import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceService } from '../services/resources.service';
import { CollectionReferenceType } from '../types/collection-reference.type';
import { ProductModel } from '../models/product.model';

@Injectable({
  providedIn: 'root',
})
export class ProductsService extends ResourceService {
  private readonly productsCollection: CollectionReferenceType;

  constructor() {
    super();
    this.productsCollection = this.collection('products');
  }

  getProducts(): Observable<ProductModel[]> {
    return this.collectionData<ProductModel>(this.productsCollection);
  }
}
