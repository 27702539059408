import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs';
import {
  Add,
  defaultEntityState,
  EntityState,
  EntityStateModel,
  IdStrategy,
  Reset,
} from '@ngxs-labs/entity-state';
import { ProductsService } from '../resources/products.service';
import { LoadAndListenProducts } from './products.actions';
import { ProductModel } from '../models/product.model';

@State<EntityStateModel<ProductModel>>({
  name: 'products',
  defaults: defaultEntityState(),
})
@Injectable()
export class ProductsState
  extends EntityState<ProductModel>
  implements NgxsOnInit
{
  constructor(private productsService: ProductsService) {
    super(ProductsState, 'id', IdStrategy.EntityIdGenerator);
  }

  ngxsOnInit(ctx: StateContext<EntityStateModel<ProductModel>>) {
    ctx.dispatch(new LoadAndListenProducts());
  }

  @Action(LoadAndListenProducts)
  loadProducts(ctx: StateContext<EntityStateModel<ProductModel>>) {
    return this.productsService.getProducts().pipe(
      tap(products => {
        ctx.dispatch(new Reset(ProductsState));
        ctx.dispatch(new Add(ProductsState, products));
      }),
      catchError(error => {
        console.log(error);
        throw error;
      })
    );
  }
}
