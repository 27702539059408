import { CategoryModel } from '../models/category.model';

export class LoadAndListenCategories {
  static readonly type = '[Categories] Load and Listen Categories';
}

export class SelectCategory {
  static readonly type = '[Categories] Select Category';
  constructor(public category: CategoryModel) {}
}
