import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs';
import {
  Add,
  defaultEntityState,
  EntityState,
  EntityStateModel,
  IdStrategy,
  Reset,
  SetActive,
} from '@ngxs-labs/entity-state';
import { CategoryModel } from '../models/category.model';
import { LoadAndListenCategories, SelectCategory } from './categories.actions';
import { CategoriesService } from '../resources/categories.service';

@State<EntityStateModel<CategoryModel>>({
  name: 'categories',
  defaults: defaultEntityState(),
})
@Injectable()
export class CategoriesState
  extends EntityState<CategoryModel>
  implements NgxsOnInit
{
  constructor(private categoriesService: CategoriesService) {
    super(CategoriesState, 'id', IdStrategy.EntityIdGenerator);
  }

  ngxsOnInit(ctx: StateContext<EntityStateModel<CategoryModel>>) {
    ctx.dispatch(new LoadAndListenCategories());
  }

  @Action(LoadAndListenCategories)
  loadCategories(ctx: StateContext<EntityStateModel<CategoryModel>>) {
    return this.categoriesService.getCategories().pipe(
      tap(categories => {
        ctx.dispatch(new Reset(CategoriesState));
        ctx.dispatch(new Add(CategoriesState, categories));
      }),
      catchError(error => {
        console.log(error);
        throw error;
      })
    );
  }

  @Action(SelectCategory)
  selectCategory(
    ctx: StateContext<EntityStateModel<CategoryModel>>,
    action: SelectCategory
  ) {
    ctx.dispatch(new SetActive(CategoriesState, action.category.id));
  }
}
