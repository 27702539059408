export const environment = {
  production: false,
  firebase: {
    projectId: 'menuize-staging',
    appId: '1:526462938458:web:7b99590f81108b3a801763',
    storageBucket: 'menuize-staging.appspot.com',
    apiKey: 'AIzaSyCO4YUQ8gf9Jtrn1wO4yTHLY3fwxf0bRiE',
    authDomain: 'menuize-staging.firebaseapp.com',
    messagingSenderId: '526462938458',
    measurementId: 'G-CPS73SW028',
  },
  orders: {
    tax: 0.1,
  },
};
