import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceService } from '../services/resources.service';
import { CollectionReferenceType } from '../types/collection-reference.type';
import { CategoryModel } from '../models/category.model';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService extends ResourceService {
  private readonly categoriesCollection: CollectionReferenceType;

  constructor() {
    super();
    this.categoriesCollection = this.collection('categories');
  }

  getCategories(): Observable<CategoryModel[]> {
    return this.collectionData<CategoryModel>(this.categoriesCollection);
  }
}
